<template>
  <div>
    <transition name="el-fade-in-linear">
      <div class="floor_nav" v-if="show">

        <!-- <el-anchor-link v-for="(item, i) in list" :key="i" :href="item.id" title="指向第一个锚点">{{ item.title }}</el-anchor-link> -->
        <div v-for="(item, i) in list" :key="i" class="item" :class="[current == i ? 'active' : '']" @click="handleGo(item.id)" >
          {{ item.title }}
        </div>

        <div class="back_top" @click="backToTop">
          <div class="img">
            <i class="el-icon-arrow-up"></i>
          </div>
          <span>顶部</span>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import { throttle } from "@/utils/tool.js";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      current: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", throttle(this.handleScroll)); // 监听页面滚动
  },
  methods: {
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      
      if (scrollTop >= 580) {
        this.show = true;
      } else {
        this.show = false;
      }

      let offsetTopArr = [];
      this.list.forEach((v) => {
        offsetTopArr.push(document.getElementById(v.id).offsetTop);
      });

      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        // console.log(scrollTop,'高度',offsetTopArr[n])
        if (scrollTop >= offsetTopArr[n] + 300) { // -400
          navIndex = n;
        }
      }

      this.current = navIndex;
    },
    handleGo(id) {
      console.log(id)
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    backToTop() {
      document.getElementById("index").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.floor_nav {
  width: 60px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #eeeeee;
  border-radius: 8px;
  position: fixed;
  top: 25%;
  left: 4%;
  z-index: 99;
  padding: 14px 16px;
  box-sizing: border-box;
  color: #333333;
  line-height: 18px;
  .item {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    &:first-child {
      padding-top: 0;
    }
    &.active {
      color: @themeColor;
    }
  }
  .back_top {
    font-weight: bold;
    color: @themeColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .img {
    font-size: 16px;
  }
}
</style>
